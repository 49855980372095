// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-data-protection-js": () => import("./../../../src/pages/DataProtection.js" /* webpackChunkName: "component---src-pages-data-protection-js" */),
  "component---src-pages-emergency-js": () => import("./../../../src/pages/Emergency.js" /* webpackChunkName: "component---src-pages-emergency-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/Imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

